import React from "react";
import { useSelector } from "react-redux";
import { Notification, Sidebar } from "react-admin";
import { AppBar } from "components/AppBar";
import { Menu } from "components/Menu";

import { NewUserRedirect } from "resources/Profile/NewUserRedirect";
import { VerifyEmailBanner } from "resources/Profile/VerifyEmailBanner";
import { Flex } from "components/Design";

const Layout = ({ children, dashboard, logout, title }) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  return (
    <Flex
      minHeight="100vh"
      zIndex={1}
      bg="background.default"
      position="relative"
    >
      <AppBar title={title} open={open} logout={logout} />
      <Flex dir="row" flexGrow="1" pt={7}>
        <Sidebar classes={null}>
          <Menu />
        </Sidebar>
        <Flex flexGrow="2" p={2}>
          <NewUserRedirect />
          <VerifyEmailBanner />
          {children}
        </Flex>
      </Flex>
      <Notification />
    </Flex>
  );
};

export { Layout };
