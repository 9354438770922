import React, { useContext, useState } from "react";
import { AuthInfoContext } from "utils/AuthInfoContext";
import { Button } from "@material-ui/core";
import { Flex, Text, GradientBox } from "components/Design";
import linkToInbox from "link-to-inbox";

const supported = [
  "gmail.com",
  "googlemail.com",
  "outlook.com",
  "live.com",
  "hotmail.com",
  "web.de",
  "gmx.de",
];

function VerifyEmailBanner() {
  const [didClick, setDidClick] = useState(false);
  const authInfo = useContext(AuthInfoContext);
  if (
    !authInfo.email_verified &&
    !window.location.href.includes("/profiles/create")
  ) {
    return (
      <GradientBox gradient="warn" mb={4} color="util.white">
        <Flex
          dir="row"
          ml={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Text variant="h6" flex="1" mr={3}>
            Du musst noch deine E-Mail Adresse bestätigen, damit deine
            Veranstaltungen auf KULTA veröffentlicht werden.
          </Text>
          {supported.includes(linkToInbox.getSpec(authInfo.email).domain) && (
            <Button
              onClick={() => {
                if (!didClick) {
                  const link = linkToInbox.getHref(authInfo.email, {
                    subject: "Verify your email",
                  });
                  window.open(link);
                  setDidClick(true);
                } else {
                  window.location.reload();
                }
              }}
              size="large"
              variant="contained"
              color="inherit"
            >
              <Flex color="util.black">
                {!didClick ? "Postfach öffnen" : "Neu Laden"}
              </Flex>
            </Button>
          )}
        </Flex>
      </GradientBox>
    );
  } else {
    return null;
  }
}

export { VerifyEmailBanner };
