import buildHasuraProvider, { buildFields, buildArgs } from "ra-data-hasura";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import gql from "graphql-tag";

/* eslint-disable no-unused-vars */

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst) => {
  return queryAst.definitions[0].selectionSet.selections;
};

// Define the additional fields that we want.
const EXTENDED_GET_EVENT_NAME = gql`
  {
    startDate
    event {
      name
      coverImage_handle
    }
  }
`;

const customBuildFields = (type, fetchType) => {
  const resourceName = type.name;

  // First take the default fields (all, but no related or nested).
  const defaultFields = buildFields(type, fetchType);

  if (resourceName === "occurrences") {
    const relatedEntities = extractFieldsFromQuery(EXTENDED_GET_EVENT_NAME);
    defaultFields.push(...relatedEntities);
  }

  // Extend other queries for other resources/fetchTypes here...

  return defaultFields;
};

const buildFieldsLog = (type, fetchType) => {
  const defaultFields = buildFields(type, fetchType);
  console.log("fields input", { type, fetch });
  console.log("fields output", defaultFields);
  return defaultFields;
};

function buildArgsLog(...args) {
  const defaultArgs = buildArgs(...args);
  console.log("args input", args);
  console.log("args output", defaultArgs);
  return defaultArgs;
}

async function createDataProvider({ rawAuth }) {
  const client = new ApolloClient({
    uri: "https://manage.kulta.app/v1/graphql",
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${rawAuth}`,
    },
  });
  const hasuraGraphQLProvider = await buildHasuraProvider(
    { client },
    { buildFields: customBuildFields }
  );
  const loggingProvider = (...args) => {
    console.log("provider args", args);
    const result = hasuraGraphQLProvider(...args);
    console.log("provider results", result);
    return result;
  };

  return { provider: hasuraGraphQLProvider, client };
}

export { createDataProvider };
