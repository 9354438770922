import React from "react";
import { Cloudinary } from "cloudinary-core";
import { Avatar } from "@material-ui/core";

var cl = new Cloudinary({ cloud_name: "hcabadqds" });

function CloudinaryAvatar({ handle = undefined, name = "No", size = 36 }) {
  return (
    <>
      {handle ? (
        <img
          style={{ width: size, height: size, borderRadius: size }}
          src={`${cl.url(handle, {
            transformation: [{ width: size, height: size, crop: "thumb" }]
          })}`}
          alt=""
        />
      ) : (
        <Avatar style={{ width: size, height: size }}>
          {name
            .split(" ")
            .map(n => n[0])
            .join("")
            .substr(0, 2)}
        </Avatar>
      )}
    </>
  );
}

export { CloudinaryAvatar };
