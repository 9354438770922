// in src/posts.js
import * as React from "react";
import { Create, Edit, SimpleForm, TextInput } from "react-admin";
import { JsonInput } from "react-admin-json-view";

function AppConfigForm({ isCreate = false, ...props }) {
  return (
    <SimpleForm {...props}>
      <TextInput disabled={!isCreate} label="Id" source="id" fullWidth />
      <JsonInput
        source="config"
        jsonString={false}
        reactJsonOptions={{
          name: null,
          style: {
            fontWeight: "bold",
            fontSize: 14,
          },
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: true,
          quotesOnKeys: false,
          displayObjectSize: false,
          displayArrayKey: false,
          sortKeys: true,
        }}
      />
    </SimpleForm>
  );
}

const AppConfigEdit = (props) => (
  <Edit {...props} mutationMode={"optimistic"}>
    <AppConfigForm />
  </Edit>
);

const AppConfigCreate = (props) => (
  <Create {...props} mutationMode={"optimistic"}>
    <AppConfigForm isCreate={true} />
  </Create>
);

export { AppConfigEdit, AppConfigCreate };
