import React, { useState, useCallback, createContext, useContext } from "react";
import { useField } from "react-final-form";
import { Box, Button } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { Flex } from "components/Design";

const PickerContext = createContext((s = false) => {});

function DateTimeInput({ source, label, ...rest }) {
  const {
    input: { onChange, value },
    meta: { touched, error },
  } = useField(source);
  return (
    <DateTimeInputControlled
      name={source}
      label={label}
      onChange={onChange}
      value={value}
      touched={touched}
      error={error}
      {...rest}
    />
  );
}

function DateInputControlled({
  name,
  label,
  onChange,
  value,
  touched = false,
  error = "",
  initialDate = undefined,
  ...rest
}) {
  const onDateChange = useCallback(
    (date) => {
      if (String(new Date(date)) !== "Invalid Date") {
        onChange({ target: { value: date } });
      }
    },
    [onChange]
  );

  return (
    <Box mt={2}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar={true}
          initialFocusedDate={initialDate}
          minDate={initialDate}
          variant="inline"
          inputVariant="filled"
          size="small"
          autoOk={true}
          name={name}
          // @ts-ignore
          ampm={false}
          label={label}
          value={value || null}
          onChange={onDateChange}
          error={!!(touched && error)}
          helperText={touched && error ? error : " "}
          format="dd.MM.yyyy"
          placeholder="__.__.____"
          InputLabelProps={{
            shrink: true,
          }}
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
}

function TimeField({
  name,
  label,
  onChange,
  value,
  touched = false,
  error = "",
  ...rest
}) {
  const onDateChange = useCallback(
    (date) => {
      if (String(new Date(date)) !== "Invalid Date") {
        onChange({ target: { value: date } });
      }
    },
    [onChange]
  );
  return (
    <Box mt={2}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          variant="dialog"
          inputVariant="filled"
          size="small"
          name={name}
          ampm={false}
          label={label}
          // @ts-ignore
          view={["hours", "minutes"]}
          value={value || null}
          disableToolbar={true}
          autoOk={true}
          onChange={onDateChange}
          error={!!(touched && error)}
          helperText={touched && error ? error : " "}
          format="HH:mm"
          placeholder="__:__"
          InputLabelProps={{
            shrink: true,
          }}
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
}

const DateTimeInputControlled = ({
  name,
  label,
  onChange,
  value,
  touched = false,
  error = "",
  initialDate = undefined,
  ...rest
}) => {
  const onDateChange = useCallback(
    (date) => {
      let utcTime = null;
      try {
        const newTime = zonedTimeToUtc(date, "Europe/Berlin");
        newTime.toISOString();
        utcTime = newTime;
      } catch (e) {
        // not a complete entry
      }
      if (utcTime) {
        onChange({ target: { value: utcTime.toISOString() } });
      }
    },
    [onChange]
  );
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box mt={2}>
      <PickerContext.Provider value={setIsOpen}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            initialFocusedDate={initialDate}
            minDate={initialDate}
            variant="inline"
            inputVariant="filled"
            size="small"
            name={name}
            ampm={false}
            label={label}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            value={value ? utcToZonedTime(value, "Europe/Berlin") : null}
            onChange={(value) => {
              onDateChange(value);
            }}
            hideTabs={true}
            disableToolbar={false}
            error={!!(touched && error)}
            helperText={touched && error ? error : " "}
            format="dd.MM.yyyy HH:mm"
            placeholder="__.__.____ __:__"
            ToolbarComponent={Toolbar}
            InputLabelProps={{
              shrink: true,
            }}
            {...rest}
          />
        </MuiPickersUtilsProvider>
      </PickerContext.Provider>
    </Box>
  );
};

function Toolbar({ setOpenView, openView, ...rest }) {
  const setIsOpen = useContext(PickerContext);
  return (
    <Flex dir="row">
      <ToggleButtonGroup
        value={openView}
        exclusive
        size="small"
        // @ts-ignore
        onChange={({ currentTarget: { value } }) => setOpenView(value)}
        aria-label="Datumsauswahl Tab"
      >
        <ToggleButton
          style={{ color: "black" }}
          value="date"
          aria-label="Datum"
        >
          Datum
        </ToggleButton>
        <ToggleButton
          style={{ color: "black" }}
          value="hours"
          aria-label="Stunde"
        >
          Stunde
        </ToggleButton>
        <ToggleButton
          style={{ color: "black" }}
          value="minutes"
          aria-label="Minute"
        >
          Minute
        </ToggleButton>
      </ToggleButtonGroup>
      <Flex flex="1" />
      <Button onClick={() => setIsOpen(false)}>Fertig</Button>
    </Flex>
  );
}
export {
  DateTimeInput,
  DateInputControlled,
  DateTimeInputControlled,
  TimeField,
};
