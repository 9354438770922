import React from "react";
import { Link, useTheme } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Flex, Text, GradientBox } from "components/Design";
import { startOfWeek, endOfWeek } from "date-fns";

function StatBox({ name, value }) {
  return (
    <Flex alignItems="center" mx={3}>
      <Text variant="h4" textAlign="center">
        {value}
      </Text>
      <Text variant="button" textAlign="center">
        {name}
      </Text>
    </Flex>
  );
}

function StatsBox() {
  const { data } = useQuery(
    gql`
      query VanityStats(
        $now: timestamptz
        $weekStart: timestamptz
        $weekEnd: timestamptz
      ) {
        dates: occurrences_aggregate(distinct_on: [event_id]) {
          aggregate {
            count
          }
        }
        past: occurrences_aggregate(
          where: { startDate: { _lt: $now } }
          distinct_on: [event_id]
        ) {
          aggregate {
            count
          }
        }
        future: occurrences_aggregate(
          where: { startDate: { _gte: $now } }
          distinct_on: [event_id]
        ) {
          aggregate {
            count
          }
        }
        week: occurrences_aggregate(
          where: {
            _and: [
              { startDate: { _gte: $weekStart } }
              { startDate: { _lte: $weekEnd } }
            ]
          }
          distinct_on: [event_id]
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    {
      variables: {
        now: new Date().toUTCString(),
        weekStart: startOfWeek(new Date(), { weekStartsOn: 1 }).toUTCString(),
        weekEnd: endOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
      },
      fetchPolicy: "cache-and-network",
    }
  );
  return (
    <GradientBox mb={3} gradient="violet">
      <Flex dir="row" justifyContent="space-between" ml={2} alignItems="center">
        <Flex color="util.white">
          <Text variant="h5" bold style={{ opacity: 0.9 }}>
            Willkommen bei KULTA
          </Text>
          <Text variant="h6" style={{ opacity: 0.9 }}>
            Hier kannst du deine Veranstaltungen verwalten
          </Text>
        </Flex>
        {data && (
          <Flex dir="row" display="flex" alignItems="center" color="util.white">
            <StatBox value={data.dates.aggregate.count} name="Insgesamt" />
            <StatBox value={data.week.aggregate.count} name="Diese Woche" />
            <StatBox value={data.past.aggregate.count} name="Vergangen" />
          </Flex>
        )}
      </Flex>
    </GradientBox>
  );
}

function ContactBox() {
  const th = useTheme();
  return (
    <GradientBox gradient="lightviolet" m={3}>
      <Text>
        Du hast Feedback oder eine Frage? Melde dich gerne bei uns.{" "}
        <Link
          style={{ color: th.palette.info.dark }}
          href="mailto:veranstalter@kulta.app"
        >
          Schreib uns eine E-Mail.
        </Link>
      </Text>
    </GradientBox>
  );
}

export { ContactBox, StatsBox, GradientBox };
