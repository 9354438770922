// in src/posts.js
import * as React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
} from "react-admin";
import {
  ManyToManyReferenceContextProvider,
  ReferenceManyToManyInput,
  DualListInput,
} from "@react-admin/ra-relationships";
import { OccurrenceField } from "components/OccurrenceField";

const statePublishedChoices = [
  { id: "DRAFT", name: "DRAFT" },
  { id: "PUBLIC", name: "PUBLIC" },
];

function CollageForm({ isCreate = false, ...props }) {
  return (
    <ManyToManyReferenceContextProvider {...props}>
      <SimpleForm>
        <TextInput source="id" disabled={!isCreate} fullWidth />
        <TextInput source="name" fullWidth />
        <BooleanInput
          label="Nicht als Collage anzeigen (unter Events etc)"
          source="internal"
          fullWidth
        />
        <SelectInput
          choices={statePublishedChoices}
          source="statePublished"
          fullWidth
        />
        {!isCreate && (
          <ReferenceManyToManyInput
            reference="occurrences"
            through="collage_contains_occurrence"
            using="collage_id,occurrence_id"
            source="id"
            fullWidth
            label="Events"
          >
            <DualListInput
              availableItemsLabel="Alle Termine"
              selectedItemsLabel="In Collage"
              removeButtonLabel="Aus Collage entfernen"
              addButtonLabel="In Collage einfügen"
              translateChoice={false}
              optionText={<OccurrenceField />}
            />
          </ReferenceManyToManyInput>
        )}
      </SimpleForm>
    </ManyToManyReferenceContextProvider>
  );
}

const CollageEdit = (props) => (
  <Edit {...props} undoable={false}>
    <CollageForm />
  </Edit>
);

const CollageCreate = (props) => (
  <Create {...props} undoable={false}>
    <CollageForm isCreate={true} />
  </Create>
);

export { CollageEdit, CollageCreate };
