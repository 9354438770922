import React from "react";
import {
  Create,
  useRedirect,
  FormWithRedirect,
  SaveButton,
  useCreate,
} from "react-admin";
import { EssentialRow } from "./EventEdit";
import { Box, Toolbar } from "@material-ui/core";
import { Required } from "components/Required";

function EventCreate(props) {
  const [create] = useCreate();

  const redirect = useRedirect();
  return (
    <Box>
      <Create
        {...props}
        resource="events"
        onSuccess={({ data, ...rest }) => {
          create(
            "occurrences",
            { event_id: data.id },
            {
              onSuccess: () => {
                redirect(`/_events_list_veranstalter/${data.id}`);
              },
            }
          );
        }}
      >
        <FormWithRedirect
          {...props}
          initialValues={{ eventType: "Other" }}
          render={(formProps) => (
            // here starts the custom form layout
            <form>
              <Box mx={3}>
                <EssentialRow isCreate={true} />
              </Box>
              <Toolbar>
                <Box display="flex" justifyContent="flex-end" width="100%">
                  <SaveButton
                    label="Weiter"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                  />
                </Box>
              </Toolbar>
            </form>
          )}
        />
      </Create>
      <Required />
    </Box>
  );
}

export { EventCreate };
