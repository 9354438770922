import React from "react";
import { Flex, Text } from "components/Design";

function Required() {
  return (
    <Flex dir="row" pt={2} justifyContent="flex-end" width="100%">
      <Text>* Pflichtfeld</Text>
    </Flex>
  );
}

const required = (value) => (value ? undefined : "Pflichtfeld");

export { Required, required };
