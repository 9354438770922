import React, { useContext, useEffect } from "react";
import { useRedirect, useGetOne } from "react-admin";
import { AuthInfoContext } from "utils/AuthInfoContext";
import { GradientBox, Flex, Text } from "components/Design";
import { Button } from "@material-ui/core";

function NewUserRedirect() {
  const redirect = useRedirect();
  const authInfo = useContext(AuthInfoContext);
  const userID = authInfo.sub;
  const { data, loaded } = useGetOne("profiles", userID);
  useEffect(() => {
    if (loaded && !data && !window.location.href.includes("/profiles/create")) {
      redirect("/profiles/create");
    }
  }, [data, loaded, redirect]);
  if (loaded && !data && !window.location.href.includes("/profiles/create")) {
    return (
      <GradientBox gradient="warn" mx={3} color="util.white">
        <Flex
          dir="row"
          ml={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Text variant="h6">
            Bevor du KULTA für Veranstalter benutzen kannst, musst du dein
            Profil ausfüllen
          </Text>
          <Button
            onClick={() => redirect("/profiles/create")}
            size="large"
            variant="contained"
            color="inherit"
          >
            <Flex color="util.black">{"Profil ausfüllen"}</Flex>
          </Button>
        </Flex>
      </GradientBox>
    );
  } else {
    return null;
  }
}

export { NewUserRedirect };
