import React from "react";
import { Tooltip } from "@material-ui/core";
import { utcToZonedTime, format } from "date-fns-tz";
import { Flex } from "components/Design";
import { formatDistanceToNow } from "date-fns";
import deLocale from "date-fns/locale/de";

function formatForTimezone(date) {
  return format(utcToZonedTime(date, "Europe/Berlin"), "dd.MM.yyyy HH:mm", {
    timeZone: "Europe/Berlin",
  });
}

function DateTimeField({ source, record = {}, label = "" }) {
  const value = record[source];

  return <span>{value ? formatForTimezone(value) : "-"}</span>;
}

function AgoField({ source, record = {}, label = "" }) {
  const value = record[source];
  const dateString = value ? formatForTimezone(value) : "-";
  let canParse = false;
  try {
    formatDistanceToNow(new Date(value));
    canParse = true;
  } catch (e) {}
  const agoString = canParse
    ? formatDistanceToNow(new Date(value), {
        addSuffix: true,
        locale: deLocale,
      })
    : "-";

  return (
    <Tooltip title={dateString}>
      <Flex>{agoString}</Flex>
    </Tooltip>
  );
}

export { DateTimeField, AgoField, formatForTimezone };
