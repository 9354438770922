import React from "react";
import { useSelector } from "react-redux";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { MenuItemLink, useGetOne, Link, useRedirect } from "react-admin";
import { useRole, useUserID } from "utils/AuthInfoContext";
import { CloudinaryAvatar } from "components/CloudinaryAvatar";
import {
  ViewList,
  AccountCircle,
  PlaceRounded,
  DescriptionRounded,
  EventNoteRounded,
  HistoryRounded,
} from "@material-ui/icons";
import { ContactBox } from "components/Boxes";
import { Add, AlternateEmailSharp, Settings } from "@material-ui/icons";
import { Flex, Text } from "components/Design";

function shorten(text, max) {
  if (text.length <= max) {
    return text;
  } else {
    return text.substring(0, max) + "...";
  }
}

function UserSection({ open }) {
  const userID = useUserID();
  const { data } = useGetOne("profiles", userID);
  return (
    <Flex dir="row" alignItems="center" mx={3} mt={2} mb={4}>
      <Link to={`/_events_list_veranstalter`}>
        <CloudinaryAvatar
          handle={data?.image_handle}
          name={data?.name}
          size={open ? 48 : 30}
        />
      </Link>
      {open && (
        <Flex ml={3}>
          <Link to={`/_events_list_veranstalter`}>
            <Text variant="h6">{data ? shorten(data.name, 13) : "..."}</Text>
          </Link>
          <Link to={`/profiles/${userID}`}>
            {" "}
            <Text variant="button">Profil bearbeiten</Text>
          </Link>
        </Flex>
      )}
    </Flex>
  );
}

const Menu = () => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const userID = useUserID();
  const { data } = useGetOne("profiles", userID);
  const redirect = useRedirect();
  const role = useRole();
  if (data) {
    return (
      <Flex mt={4}>
        <UserSection open={open} />
        {open ? (
          <Flex m={3}>
            <Button
              onClick={() => redirect("/events/create")}
              size="large"
              variant="contained"
              color="primary"
              startIcon={<Add />}
            >
              {open ? "Event Erstellen" : "+"}
            </Button>
          </Flex>
        ) : (
          <Tooltip title="Event erstellen">
            <IconButton
              onClick={() => redirect("/events/create")}
              color="primary"
              aria-label="Event"
              component="span"
            >
              <Add />
            </IconButton>
          </Tooltip>
        )}
        <MenuItemLink
          to={`/`}
          primaryText={open ? "Events" : ""}
          leftIcon={<EventNoteRounded />}
        />
        <Flex ml={open ? 3 : 0}>
          <MenuItemLink
            to={`/_events_list_veranstalter?filter=%7B%22in_past%22%3Afalse%7D`}
            primaryText={open ? "Aktuell" : ""}
            leftIcon={<ViewList />}
          />
          <MenuItemLink
            to={`/_events_list_veranstalter?filter=%7B%22in_past%22%3Atrue%7D`}
            primaryText={open ? "Vergangen" : ""}
            leftIcon={<HistoryRounded />}
          />
        </Flex>
        <MenuItemLink
          to={`/profiles/${userID}`}
          primaryText={open ? "Profil" : ""}
          leftIcon={<AccountCircle />}
        />
        <Flex ml={open ? 3 : 0}>
          <MenuItemLink
            to={`/profiles/${userID}`}
            primaryText={open ? "Infos" : ""}
            leftIcon={<DescriptionRounded />}
          />
          <MenuItemLink
            to={`/profiles/${userID}/1`}
            primaryText={open ? "Locations" : ""}
            leftIcon={<PlaceRounded />}
          />
        </Flex>
        {role === "editor" && (
          <MenuItemLink
            to={`/appConfig`}
            primaryText={open ? "Config" : ""}
            leftIcon={<Settings />}
          />
        )}
        {role === "editor" && (
          <MenuItemLink
            to={`/collages`}
            primaryText={open ? "Collagen" : ""}
            leftIcon={<Settings />}
          />
        )}
        {role === "editor" && (
          <MenuItemLink
            to={`/notices`}
            primaryText={open ? "Notices" : ""}
            leftIcon={<Settings />}
          />
        )}
        <Flex m={3} />
        {open ? (
          <ContactBox />
        ) : (
          <Tooltip title="E-Mail an KULTA">
            <IconButton
              onClick={() =>
                (window.location.href = "mailto:veranstalter@kulta.app")
              }
              color="primary"
              aria-label="E-Mail"
              component="span"
            >
              <AlternateEmailSharp />
            </IconButton>
          </Tooltip>
        )}
      </Flex>
    );
  } else {
    return (
      <Flex>
        {" "}
        {open ? (
          <ContactBox />
        ) : (
          <Tooltip title="E-Mail an KULTA">
            <IconButton
              onClick={() =>
                (window.location.href = "mailto:veranstalter@kulta.app")
              }
              color="primary"
              aria-label="E-Mail"
              component="span"
            >
              <AlternateEmailSharp />
            </IconButton>
          </Tooltip>
        )}
      </Flex>
    );
  }
};

export { Menu };
