import React from "react";
import { Cloudinary } from "cloudinary-core";
import { Flex } from "components/Design";
import { Warning } from "@material-ui/icons";

var cl = new Cloudinary({ cloud_name: "hcabadqds" });

function CloudinaryField({
  source,
  record = {},
  size = 32,
  label = "",
  face = false,
}) {
  if (!record[source]) {
    return (
      <Flex dir="row" alignItems="center">
        <Warning />
        <Flex ml={1}>Bild fehlt</Flex>
      </Flex>
    );
  } else {
    return (
      <img
        style={{ width: size * 2, height: size }}
        src={`${cl.url(record[source], {
          transformation: [
            {
              width: size * 2,
              height: size,
              crop: "thumb",
              gravity: face ? "faces" : undefined,
            },
          ],
        })}`}
        alt=""
      />
    );
  }
}

export { CloudinaryField };
