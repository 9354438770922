import { createContext, useContext } from "react";

const AuthInfoContext = createContext(null);

function useUserID() {
  const authInfo = useContext(AuthInfoContext);
  const userID = authInfo.sub;
  return userID;
}

function useRole() {
  const authInfo = useContext(AuthInfoContext);
  return authInfo["https://kulta.app/claims"].default_role;
}

export { AuthInfoContext, useUserID, useRole };
