import React from "react";

import {
  Box,
  Typography,
  BoxProps,
  TypographyVariant,
} from "@material-ui/core";

const gradients = {
  darkgrey: "radial-gradient(circle at bottom left, #CFD8DC, #546E7A)",
  soft: "radial-gradient(circle at top left, #CFD8DC, #FAF2F7)",
  warn: "radial-gradient(circle at bottom left, #BF360C, #F57C00)",
  blue: "radial-gradient(circle at bottom left, #2962FF, #3949AB)",
  green: "radial-gradient(circle at bottom left, #2E7D32, #4DB6AC)",
  violet: "radial-gradient(circle at bottom left, #4527A0, #7B1FA2)",
  lightviolet: "radial-gradient(circle at bottom left, #D1C4E9, #F3E5F5)",
  wild: "radial-gradient(circle at bottom left, #D500F9, #FFA000)",
  revolut: "radial-gradient(circle at bottom left, #6200EA, #1976D2)",
};

function GradientBox({ gradient = "darkgrey", style = {}, ...rest }) {
  return (
    <Flex
      p={3}
      borderRadius={8}
      style={{
        background: gradients[gradient],
        ...style,
      }}
      {...rest}
    />
  );
}

type FlexDirection = "column" | "row";

interface FlexProps extends BoxProps {
  dir?: FlexDirection;
  direction?: FlexDirection;
  bg?: string;
}

function _Flex({ dir, direction, bg = undefined, ...rest }: FlexProps, ref) {
  return (
    <Box
      // @ts-ignore
      ref={ref}
      display="flex"
      bgcolor={bg}
      flexDirection={direction || dir || "column"}
      {...rest}
    />
  );
}

const Flex: typeof _Flex = React.forwardRef(_Flex);

interface TextProps extends BoxProps {
  variant?: "inherit" | TypographyVariant;
  color?: string;
  bold?: boolean;
  children: any;
}

function _Text(
  {
    variant = undefined,
    color = "inherit",
    bold = false,
    children,
    ...rest
  }: TextProps,
  ref
) {
  return (
    <Box
      {...rest}
      // @ts-ignore
      ref={ref}
    >
      <Typography
        variant={variant}
        style={bold ? { fontWeight: "bold" } : {}}
        // @ts-ignore
        color={color}
      >
        {children}
      </Typography>
    </Box>
  );
}

const Text: typeof _Text = React.forwardRef(_Text);

export { Flex, Text, GradientBox };
