import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from "react-admin";
import { CloudinaryInput } from "components/CloudinaryInput";
import { useUserID } from "utils/AuthInfoContext";
import { Required, required } from "components/Required";
import { Flex } from "components/Design";

function LocationCreate(props) {
  const userID = useUserID();
  return (
    <Flex>
      <Create {...props}>
        <SimpleForm redirect={`/profiles/${userID}/1`}>
          <LocationFields />
        </SimpleForm>
      </Create>
      <Required />
    </Flex>
  );
}

const LocationTitle = ({ record = undefined }) => {
  return <span>{record ? record.name : ""}</span>;
};

function LocationFields() {
  return (
    <>
      <TextInput
        validate={required}
        required
        source="name"
        label="Name"
        fullWidth
      />
      <TextInput
        multiline
        rows={2}
        validate={required}
        required
        source="address"
        label="Adresse"
        fullWidth
      />
      <BooleanInput
        source="wheelchairAccessible"
        label="Für RollstuhlfahrerInnen zugänglich"
        fullWidth
      />
      <CloudinaryInput source="image_handle" />
      <ReferenceInput
        required
        validate={required}
        source="city_id"
        label="Region"
        reference="cities"
        fullWidth
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </>
  );
}

function LocationEdit(props) {
  const userID = useUserID();
  return (
    <Flex>
      <Edit {...props} title={<LocationTitle />} mutationMode={"optimistic"}>
        <SimpleForm redirect={`/profiles/${userID}/1`}>
          <LocationFields />
        </SimpleForm>
      </Edit>
      <Required />
    </Flex>
  );
}

export { LocationEdit, LocationCreate };
