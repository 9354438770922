import React from "react";
import { Tooltip } from "@material-ui/core";
import { Flex } from "components/Design";

const Tip = ({ children, icon = "?" }) => {
  return (
    <Tooltip title={children} interactive>
      <Flex
        justifyContent="center"
        alignItems="center"
        bg="#5a2dde22"
        color="#5a2dde"
        borderRadius={100}
        width={30}
        height={26}
        fontSize={16}
      >
        {icon}
      </Flex>
    </Tooltip>
  );
};
export { Tip };
