import React, { useState, useEffect, useCallback } from "react";
import { LinearProgress } from "react-admin";
import { Typography, Box, TextField } from "@material-ui/core";
import { ArrowForward, Delete as DeleteIcon } from "@material-ui/icons";
import {
  DateTimeInputControlled,
  DateInputControlled,
  TimeField,
} from "components/DateTimeInput";
import { Confirmable } from "components/Confirmable";
import { Flex, Text } from "components/Design";
import {
  addDays,
  eachDayOfInterval,
  getDay,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
} from "date-fns/esm";
import { formatWithOptions } from "date-fns/esm/fp";
import de from "date-fns/locale/de";
import { useImmer } from "use-immer";
import copy from "copy-to-clipboard";
import { Touchable } from "components/Touchable";
import { Button } from "components/Button";
import MassEditPreview from "img/MassEditPreview.png";
import ListEditPreview from "img/ListEditPreview.png";

const formatFP = formatWithOptions({ locale: de });

function dayName(offset) {
  const anArbitraryMonday = new Date(2017, 9, 23);
  return formatFP("EEEE", addDays(anArbitraryMonday, offset));
}

function OccurrenceEdit({
  showExtra,
  updateOccurrences,
  occurrence,
  isDraftEvent,
}) {
  if (occurrence.isDeleted) {
    return null;
  } else {
    return (
      <Flex dir="row">
        <Flex dir="row">
          <DateTimeInputControlled
            value={occurrence.startDate}
            onChange={({ target: { value } }) =>
              updateOccurrences((draft) => {
                draft[String(occurrence.id)].startDate = value;
              })
            }
            disabled={occurrence.cancelled}
            required
            label="Beginn"
            name="startDate"
          />
          <Flex mx={2} mt={4} onClick={() => copy(occurrence.id)}>
            <ArrowForward />
          </Flex>
          <DateTimeInputControlled
            initialDate={occurrence.startDate}
            value={occurrence.endDate}
            onChange={({ target: { value } }) =>
              updateOccurrences((draft) => {
                draft[String(occurrence.id)].endDate = value;
              })
            }
            disabled={occurrence.cancelled}
            label="Ende"
            name="endDate"
          />
          {showExtra && (
            <Flex ml={3}>
              <TextField
                variant="filled"
                name="occurrence-title"
                value={occurrence.title}
                onChange={({ target: { value } }) =>
                  updateOccurrences((draft) => {
                    draft[String(occurrence.id)].title = value;
                  })
                }
                label="Hinweis"
                size="small"
                disabled={occurrence.cancelled}
              />
            </Flex>
          )}
          {showExtra && (
            <Flex pt={3}>
              <Confirmable
                onConfirm={() =>
                  updateOccurrences((draft) => {
                    draft[String(occurrence.id)].isDeleted = true;
                  })
                }
                icon={<DeleteIcon />}
                red
                title="Termin Löschen"
              >
                <Text>
                  Wenn du einen Fehler gemacht hast, kannst du den Termin
                  löschen. Andernfalls solltest du ihn lieber absagen.
                </Text>
                <Text mt={2}>
                  Erst wenn du das ganze Event speicherst, wird der Termin
                  tatsächlich gelöscht.
                </Text>
              </Confirmable>
            </Flex>
          )}
        </Flex>
        {!isDraftEvent && (
          <Flex
            pt={occurrence.cancelled ? 2 : 3}
            px={3}
            alignItems="center"
            width={160}
          >
            {occurrence.cancelled && <Text>Termin abgesagt</Text>}
            <Confirmable
              link={occurrence.cancelled}
              label={occurrence.cancelled ? "wiedereinstellen" : "Absagen"}
              title={
                occurrence.cancelled
                  ? "Termin wiedereinstellen"
                  : "Termin absagen"
              }
              onConfirm={() =>
                updateOccurrences((draft) => {
                  draft[String(occurrence.id)].cancelled =
                    !occurrence.cancelled;
                })
              }
            >
              {occurrence.cancelled ? (
                <Text>
                  Dieser Termin ist abgesagt. Du kannst ihn wiedereinstellen,
                  falls er doch stattfindent.
                </Text>
              ) : (
                <>
                  <Text>
                    Wenn du den Termin absagst, werden InteressentInnen die ihn
                    schon gespeichtert haben darüber informiert.
                  </Text>
                  <Text mt={2}>
                    Erst wenn du das ganze Event speicherst, wird der Termin
                    tatsächlich abgesagt.
                  </Text>
                  <Flex mt={3}>
                    <TextField
                      variant="filled"
                      multiline
                      rows={2}
                      name="reason"
                      value={occurrence.data?.reason || ""}
                      onChange={({ target: { value } }) =>
                        updateOccurrences((draft) => {
                          draft[String(occurrence.id)].data.reason = value;
                        })
                      }
                      label="Grund der Absage"
                      size="small"
                    />
                  </Flex>
                </>
              )}
            </Confirmable>
          </Flex>
        )}
      </Flex>
    );
  }
}

function makeID() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

function RangeInputRow({ offset, range, updateRanges }) {
  return (
    <Flex dir="row">
      <TimeField
        name="startTime"
        value={range.startTime}
        onChange={({ target: { value } }) => {
          updateRanges((draft) => {
            draft[offset][range.id].startTime = value;
          });
        }}
        style={{ width: 144 }}
        label="Beginn"
      />
      <Flex mx={2} mt={4}>
        <ArrowForward />
      </Flex>
      <TimeField
        name="endTime"
        value={range.endTime}
        onChange={({ target: { value } }) => {
          updateRanges((draft) => {
            draft[offset][range.id].endTime = value;
          });
        }}
        style={{ width: 144 }}
        label="Ende"
      />
      <Button
        icon="delete"
        onClick={() =>
          updateRanges((draft) => {
            delete draft[offset][range.id];
          })
        }
      />
    </Flex>
  );
}

function AddRangeButton({ onClick, label = "Zeit", secondary = false }) {
  return (
    <Box mr={3}>
      <Button
        onClick={onClick}
        secondary={secondary}
        icon="add"
        children={label}
      />
    </Box>
  );
}

function simpleDateToTime(date) {
  return formatFP("HH:mm", date);
}

function mashDateTime(date: Date, time: Date) {
  return setMinutes(setHours(date, getHours(time)), getMinutes(time));
}

function MassOccurrenceEdit({ occurrences, updateOccurrences, onCreated }) {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [ranges, updateRanges] = useImmer([{}, {}, {}, {}, {}, {}, {}]);
  const _first = ranges.find((o) => Object.keys(o).length > 0);
  const first =
    _first &&
    Object.values(_first).filter(
      (o: { startTime: any; endTime: any }) => o.startTime && o.endTime
    );

  const createDates = useCallback(() => {
    updateOccurrences((draft) => {
      Object.keys(draft).forEach((key) => {
        draft[key].isDeleted = true;
      });
      const days = eachDayOfInterval({ start, end });
      days.forEach((day) => {
        const offset = getDay(day);
        const realOffset = offset === 0 ? 6 : offset - 1;
        Object.values(ranges[realOffset]).forEach((range: any) => {
          const min = 1000000000;
          const newID = Math.abs(
            Math.random() * (Number.MAX_SAFE_INTEGER - min) + min
          );
          draft[String(newID)] = {
            isNew: true,
            isDeleted: false,
            id: newID,
            startDate: mashDateTime(day, range.startTime),
            endDate: mashDateTime(day, range.endTime),
          };
        });
      });
    });
  }, [end, ranges, start, updateOccurrences]);
  return (
    <Box my={3}>
      <Flex dir="row">
        <DateInputControlled
          value={start}
          onChange={({ target: { value } }) => setStart(value)}
          label="Erster Tag"
          name="start"
        />
        <Flex mx={2} mt={4}>
          <ArrowForward />
        </Flex>
        <DateInputControlled
          value={end}
          onChange={({ target: { value } }) => setEnd(value)}
          label="Letzer Tag"
          name="end"
        />
      </Flex>
      <Flex>
        {[0, 1, 2, 3, 4, 5, 6].map((offset) => (
          <Flex
            dir="row"
            key={offset}
            py={2}
            px={3}
            style={{
              backgroundColor: offset % 2 === 1 ? "#f2f2f2" : "transparent",
            }}
          >
            <Flex flex="1" mt={1}>
              <Text>{dayName(offset)}</Text>
            </Flex>
            <Flex flex="6">
              {Object.values(ranges[offset]).map(
                (range: { startTime: any; endTime: any; id: string }) => (
                  <RangeInputRow
                    key={range.id}
                    range={range}
                    offset={offset}
                    updateRanges={updateRanges}
                  />
                )
              )}
              <Flex dir="row">
                <AddRangeButton
                  label={
                    Object.keys(ranges[offset]).length > 0
                      ? "Weitere Öffnungszeit"
                      : "Öffnungszeit hinzufügen"
                  }
                  onClick={() =>
                    updateRanges((draft) => {
                      const newID = makeID();
                      draft[offset][newID] = {
                        startTime: "",
                        endTime: "",
                        id: newID,
                      };
                    })
                  }
                />
                {Object.keys(ranges[offset]).length === 0 &&
                  first &&
                  first.length > 0 && (
                    <AddRangeButton
                      secondary={true}
                      label={first
                        .map(
                          (range: { startTime: any; endTime: any }) =>
                            `${simpleDateToTime(
                              range.startTime
                            )} - ${simpleDateToTime(range.endTime)}`
                        )
                        .join(",  ")}
                      onClick={() =>
                        updateRanges((draft) => {
                          first.forEach(
                            (range: { startTime: any; endTime: any }) => {
                              const newID = makeID();
                              draft[offset][newID] = {
                                startTime: range.startTime,
                                endTime: range.endTime,
                                id: newID,
                              };
                            }
                          );
                        })
                      }
                    />
                  )}
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Text variant="subtitle1">
        Für diesen Zeitraum werden wir jetzt die konkreten Termine erstellen,
        die du danach weiter bearbeiten kannst.
      </Text>
      {Object.values(occurrences).length > 1 && (
        <Text variant="subtitle2">
          {`Achtung: ${
            Object.values(occurrences).length
          } bestehende Termine werden überschrieben, wenn du auf "TERMINE NEU ERSTELLEN" klickst.`}
        </Text>
      )}
      <Button
        icon="forward"
        onClick={() => {
          createDates();
          onCreated();
        }}
      >
        {Object.values(occurrences).length > 1
          ? "Termine neu erstellen"
          : "Termine Erstellen"}
      </Button>
    </Box>
  );
}

interface EditListProps {
  occurrences: any[];
  updateOccurrences: (draft: any) => void;
  isDraftEvent?: boolean;
  showExtra?: boolean;
}

function OccurrenceListEdit({
  occurrences,
  updateOccurrences,
  isDraftEvent,
  showExtra,
}: EditListProps) {
  if (Object.keys(occurrences).length === 0) {
    return (
      <Flex my={8}>
        <LinearProgress />
      </Flex>
    );
  }
  return (
    <>
      <Box maxHeight="50vh" overflow="auto">
        {Object.values(occurrences).map((occurrence) => (
          <OccurrenceEdit
            showExtra={showExtra}
            key={occurrence.id}
            occurrence={occurrence}
            updateOccurrences={updateOccurrences}
            isDraftEvent={isDraftEvent}
          />
        ))}
      </Box>
      <Box mb={3}>
        <Button
          onClick={(e) => {
            updateOccurrences((draft) => {
              const min = 1000000000;
              const newID = Math.abs(
                Math.random() * (Number.MAX_SAFE_INTEGER - min) + min
              );
              draft[String(newID)] = {
                isNew: true,
                isDeleted: false,
                id: newID,
              };
            });
          }}
          icon="add"
          children="Weiteren Termin Hinzufügen"
        />
      </Box>
    </>
  );
}

function Header({ notDeleted }) {
  return (
    <Typography variant="h6">
      {notDeleted && notDeleted.length > 1
        ? `Termine (${notDeleted.length})`
        : "Termin"}
    </Typography>
  );
}

function OptionTile({
  onPress = () => {},
  icon = undefined,
  title,
  description = "",
}) {
  return (
    <Touchable
      feedbackStyle="highlight"
      onPress={onPress}
      style={{ flex: 1, borderRadius: 8, maxWidth: 500 }}
    >
      <Flex dir="row" p={3} alignItems="center">
        {icon && (
          <img
            style={{ width: 80, height: 80, marginTop: 8 }}
            src={icon}
            alt=""
          />
        )}
        <Flex dir="column" ml={2}>
          <Text variant="h6">{title}</Text>
          <Text variant="subtitle2">{description}</Text>
        </Flex>
      </Flex>
    </Touchable>
  );
}

function MassEdit({
  occurrences,
  updateOccurrences,
  showExtra,
  isDraftEvent,
}: EditListProps) {
  const [currentView, setCurrentView] = useState<
    "intro" | "list" | "create" | "initial"
  >("initial");

  useEffect(() => {
    if (currentView === "initial") {
      let all = Object.values(occurrences);
      if (all.length === 1 && all[0] && !all[0].startDate) {
        setCurrentView("intro");
      } else if (all.length === 0) {
      } else {
        setCurrentView("list");
      }
    }
  }, [currentView, occurrences]);

  switch (currentView) {
    case "intro": {
      return (
        <Flex>
          <Text variant="subtitle1">
            Für Ausstellungen und andere Kunstveranstaltungen bieten wir zwei
            verschiene Wege, wie du Termine angeben kannst:
          </Text>
          <Flex dir="row" py={2}>
            <OptionTile
              icon={MassEditPreview}
              onPress={() => setCurrentView("create")}
              title="Längere Ausstellung"
              description="Du kannst zuerst die Dauer der Ausstellung und die Öffnungszeiten angeben und danach Sonderfälle bearbeiten."
            />
            <OptionTile
              icon={ListEditPreview}
              onPress={() => setCurrentView("list")}
              title="Einzelne Termine"
              description="Du kannst jeden Termin einzeln erstellen und bearbeiten, so wie du es vielleicht schon von anderen Veranstaltungen kennst."
            />
          </Flex>
        </Flex>
      );
    }
    case "create": {
      return (
        <Flex alignItems="flex-start" mb={3}>
          <Flex direction="column" alignItems="flex-start">
            <Button onClick={() => setCurrentView("intro")} icon="back">
              Zurück
            </Button>
            <Text variant="subtitle1">
              Gib bitte zuerst die Laufzeit der Ausstellung und die üblichen
              Öffnungszeiten an. Im nächsten Schritt bekommst du dann die
              Möglichkeit, Sonderfälle zu bearbeiten.
            </Text>
          </Flex>
          <MassOccurrenceEdit
            occurrences={occurrences}
            updateOccurrences={updateOccurrences}
            onCreated={() => setCurrentView("list")}
          />
        </Flex>
      );
    }
    case "list": {
      return (
        <Flex alignItems="flex-start">
          <OccurrenceListEdit
            isDraftEvent={isDraftEvent}
            showExtra={showExtra}
            occurrences={occurrences}
            updateOccurrences={updateOccurrences}
          />
          <Button
            onClick={() => setCurrentView("create")}
            secondary
            icon="forward"
          >
            Termine für längere Austellung neu erstellen
          </Button>
        </Flex>
      );
    }
    case "initial": {
      return null;
    }
  }
}

function OccurrencesInput({
  occurrences,
  updateOccurrences,
  eventID,
  isDraftEvent,
  withMassEdit = false,
}) {
  useEffect(() => {
    console.log(occurrences);
  }, [occurrences]);
  const notDeleted =
    occurrences &&
    Object.values(occurrences).filter(({ isDeleted }) => !isDeleted);
  if (!occurrences) {
    return <LinearProgress />;
  } else if (!withMassEdit) {
    return (
      <div>
        <Header notDeleted={notDeleted} />
        <OccurrenceListEdit
          isDraftEvent={isDraftEvent}
          showExtra={notDeleted.length > 1}
          occurrences={occurrences}
          updateOccurrences={updateOccurrences}
        />
      </div>
    );
  } else {
    return (
      <div>
        <Header notDeleted={notDeleted} />
        <MassEdit
          occurrences={occurrences}
          updateOccurrences={updateOccurrences}
          isDraftEvent={isDraftEvent}
          showExtra={notDeleted.length > 1}
        />
      </div>
    );
  }
}

export { OccurrencesInput };
