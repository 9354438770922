// in src/posts.js
import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";

const AppConfigList = (props) => (
  <List {...props} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
    </Datagrid>
  </List>
);

export { AppConfigList };
