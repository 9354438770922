import React from "react";
import { List, Datagrid, NumberField, ChipField, TextField } from "react-admin";
import { ColorField } from "react-admin-color-input";

function NoticeList(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <TextField source="title" />
        <ChipField source="statePublished" />
        <ColorField source="gradientFrom" />
        <ColorField source="gradientTo" />
      </Datagrid>
    </List>
  );
}

export { NoticeList };
