import React from "react";
import { Button as RAButton } from "react-admin";
import { FaPlus } from "react-icons/fa";
import { ChevronLeft, ChevronRight, Delete } from "@material-ui/icons";

let iconMap = {
  add: FaPlus,
  back: ChevronLeft,
  forward: ChevronRight,
  delete: Delete,
};

interface ButtonProps {
  secondary?: boolean;
  danger?: boolean;
  icon?: keyof typeof iconMap;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: string;
}

function Button({ secondary, children, danger, icon, onClick }: ButtonProps) {
  const IconComponent = icon && iconMap[icon];
  const color = danger ? "red" : secondary ? "#888" : "#000";
  return (
    <RAButton
      label={children as string}
      style={{ opacity: secondary ? 0.7 : 1 }}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
    >
      {IconComponent && (
        <IconComponent
        //htmlColor={color}
        //@ts-ignore
        //color={color}
        />
      )}
    </RAButton>
  );
}

export { Button };
