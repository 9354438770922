import { createMuiTheme } from "@material-ui/core";
import red from "@material-ui/core/colors/red";

const theme = createMuiTheme({
  spacing: [
    2, 4, 8, 16, 24, 32, 40, 48, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64,
    64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64,
    64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64,
    64, 64, 64, 64, 64, 64,
  ],
  palette: {
    primary: { main: "#000000" },
    secondary: { main: "#000000" },
    error: red,
    util: { white: "#FFFFFF", black: "#000000" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  sidebar: {
    width: 240,
    closedWidth: 55,
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

export { theme };
