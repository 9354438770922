import React, { useState } from "react";
import { Button } from "react-admin";
import {
  Link,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Text } from "components/Design";

function Confirmable({
  onConfirm,
  link = false,
  title = "Confirm",
  children = null,
  label = "",
  icon = undefined,
  red = false,
}) {
  const [showDialog, setShowDialog] = useState(false);
  const th = useTheme();
  return (
    <>
      {link ? (
        <Link
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShowDialog(true);
          }}
          style={{ color: red ? th.palette.error.dark : th.palette.info.dark }}
        >
          <Text color="inherit">{label}</Text>
        </Link>
      ) : (
        <Button
          onClick={() => setShowDialog(true)}
          style={{
            color: red ? th.palette.error.dark : th.palette.primary.dark,
          }}
          label={label}
        >
          {icon}
        </Button>
      )}
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-label={title}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={() => setShowDialog(false)}
          />
          <Button
            onClick={(e) => {
              e.preventDefault();
              onConfirm();
              setShowDialog(false);
            }}
            style={{
              color: red ? th.palette.error.dark : th.palette.primary.dark,
            }}
            label={label}
          >
            {icon}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { Confirmable };
