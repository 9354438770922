import React, { useCallback, useEffect, useRef } from "react";
import {
  Edit,
  TextInput,
  ReferenceInput,
  BooleanInput,
  LinearProgress,
  SaveButton,
  Button,
  AutocompleteInput,
  useGetManyReference,
  FormDataConsumer,
  FormWithRedirect,
  useRedirect,
  useRefresh,
  useNotify,
} from "react-admin";

import {
  useTheme,
  Typography,
  Box,
  Toolbar,
  InputAdornment,
  Link,
} from "@material-ui/core";
import { FaSpotify, FaYoutube, FaBlind } from "react-icons/fa";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { CloudinaryInput } from "components/CloudinaryInput";
import { ChipRadioInput } from "components/ChipRadioInput";
import { Edit as EditIcon } from "@material-ui/icons";
import { Tip } from "components/Tip";
import { useQuery, useMutation as useGQLMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import DeleteConfirmButton from "components/DeleteConfirmButton";
import { useUserID } from "utils/AuthInfoContext";
import { required, Required } from "components/Required";
import { Flex, Text } from "components/Design";
import { CloudinaryAvatar } from "components/CloudinaryAvatar";
import Highlight from "react-highlighter";
import Microlink from "@microlink/react";
import parseSpotify from "spotify-uri";
import parseYoutube from "youtube-url";
import { OccurrencesInput } from "./OccurrencesInput";
import { useImmer } from "use-immer";

const EventTitle = ({ record = undefined }) => {
  return <span>{record ? record.name : ""}</span>;
};

function CategoryInput() {
  const { data } = useQuery(gql`
    query EventTypesList {
      eventTypes {
        name
        eventType
        emoji
        examples
      }
    }
  `);
  if (data) {
    return (
      <ChipRadioInput
        source="eventType"
        choices={data.eventTypes.map(
          ({ eventType, name, emoji, examples }) => ({
            id: eventType,
            name: `${emoji} ${name}`,
            examples,
          })
        )}
      />
    );
  } else {
    return <LinearProgress />;
  }
}

function LocationOption({ record = {} as any, filterValue = "", userID }) {
  const isOwn = userID === record.profile_id;
  const redirect = useRedirect();
  return (
    <Flex
      dir="row"
      width="100%"
      alignItems="center"
      style={{ opacity: isOwn ? 1 : 0.6 }}
    >
      <Flex mr={3}>
        <CloudinaryAvatar handle={record.image_handle} name={record.name} />
      </Flex>
      <Flex flex="1">
        <Text bold>
          <Highlight search={filterValue}>{record.name}</Highlight>
        </Text>
        <Text>
          <Highlight search={filterValue}>
            {record.address
              .replace("\n", ", ")
              .replace("\n", ", ")
              .substr(0, 30)}
          </Highlight>
        </Text>
      </Flex>
      {isOwn && (
        <Button
          onClick={(e) => {
            redirect(`/locations/${record.id}`);
          }}
        >
          <EditIcon />
        </Button>
      )}
    </Flex>
  );
}

function GetProps({ children, ...props }) {
  return children(props);
}

function EssentialRow({ isCreate = false }) {
  const redirect = useRedirect();
  const userID = useUserID();
  const { data: ownLocations } = useGetManyReference(
    "locations",
    "profile_id",
    userID,
    { page: 1, perPage: 20 },
    { field: "id", order: "ASC" },
    {},
    "event"
  );
  return (
    <>
      <Box display="flex">
        <Box flex={3} display="flex" flexDirection="column" mr={2}>
          <TextInput
            validate={required}
            required
            source="name"
            label="Name der Veranstaltung"
            resource="events"
            fullWidth
          />
          <CategoryInput />
        </Box>
        {ownLocations ? (
          <Box flex={2} ml={2}>
            <ReferenceInput
              validate={required}
              filter={{}}
              initialValue={Object.keys(ownLocations)[0]}
              filterToQuery={(text) => ({ "name@_ilike,address@_ilike": text })}
              source="location_id"
              label="Location"
              reference="locations"
              resource="events"
            >
              <GetProps>
                {({ setFilter, choices, ...props }) => {
                  const forSort = [...choices];
                  const sorted = forSort.sort((a, b) =>
                    a.profile_id === userID
                      ? -1
                      : b.profile_id === userID
                      ? 1
                      : 0
                  );
                  return (
                    <AutocompleteInput
                      {...props}
                      choices={sorted}
                      initialValue={Object.keys(ownLocations)[0]}
                      options={{
                        InputProps: { type: "search", autoComplete: "off" },
                      }}
                      helperText={""}
                      optionValue="id"
                      fullWidth
                      optionText={<LocationOption userID={userID} />}
                      inputText={(record) => record.name}
                      matchSuggestion={(filter, choice) => {
                        const m = filter.toLowerCase();
                        const inName = choice.name.toLowerCase().includes(m);
                        const inAddress = choice.address
                          .toLowerCase()
                          .includes(m);
                        return inName || inAddress;
                      }}
                    />
                  );
                }}
              </GetProps>
            </ReferenceInput>
            <Button
              onClick={(e) => {
                e.preventDefault();
                redirect(`/profiles/${userID}/1`);
              }}
              label={"Location hinzufügen"}
            ></Button>
            {/*!isCreate && (
              <TextInput
                source="location_hint"
                label="Zusatz"
                resource="events"
                fullWidth
                InputProps={{
                  endAdornment: <Tip>z.B. "Saal 3" oder "Im Keller"</Tip>,
                }}
              />
              )*/}
          </Box>
        ) : (
          <LinearProgress />
        )}
      </Box>
    </>
  );
}

function IncompleteLink({ children = "" }) {
  return (
    <Box height="46px" display="flex" alignItems="center">
      <Text>{children}</Text>
    </Box>
  );
}

function EventForm({ occurrences, updateOccurrences, ...props }) {
  const th = useTheme();
  return (
    <FormWithRedirect
      {...props}
      redirect={false}
      render={(formProps) => (
        <>
          <form>
            <Box mx={3} mb={3}>
              <EssentialRow />
            </Box>
          </form>
          <Box mx={3}>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <OccurrencesInput
                  isDraftEvent={formData.statePublished === "DRAFT"}
                  occurrences={occurrences}
                  updateOccurrences={updateOccurrences}
                  eventID={props.id}
                  withMassEdit={formData.eventType === "Art"}
                />
              )}
            </FormDataConsumer>
          </Box>
          <form>
            <Box mx={3}>
              <Typography variant="h6">Infos</Typography>
              <Box display="flex">
                <Box flex="2">
                  <TextInput
                    multiline
                    rows={12}
                    source="description"
                    label="Beschreibung"
                    fullWidth
                  />
                  <BooleanInput
                    source="familyFriendly"
                    label="Familienfreundliche Veranstaltung"
                  />
                  <BooleanInput source="online" label="Onlineveranstaltung" />
                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      if (formData.online) {
                        return (
                          <TextInput
                            fullWidth
                            source="onlineEventURL"
                            label="Online Event URL"
                            {...rest}
                          />
                        );
                      } else {
                        return null;
                      }
                    }}
                  </FormDataConsumer>
                </Box>
                <Box flex="1" display="flex" flexDirection="column" ml={3}>
                  <CloudinaryInput source="coverImage_handle" />
                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      if (formData.coverImage_handle) {
                        return (
                          <>
                            <TextInput
                              source="coverImage_alt"
                              label="Bildbeschreibung"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FaBlind />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <Tip>
                                    <div>
                                      Für NutzerInnen mit Seheinschränkung
                                    </div>
                                    <br />
                                    <div>
                                      z.B. "Kinoplakat von Bad Boys 2" oder
                                      "Adam Green auf einer Bühne"
                                    </div>
                                  </Tip>
                                ),
                              }}
                            />
                            <TextInput
                              source="coverImage_credit"
                              label="Bildrechte"
                              resettable
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AiOutlineCopyrightCircle />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </>
                        );
                      } else {
                        return null;
                      }
                    }}
                  </FormDataConsumer>
                </Box>
              </Box>
              <Box>
                <Box display="flex">
                  <Box width="50%" pr={4}>
                    <TextInput
                      source="youtubeURL"
                      label="Youtube URL"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FaYoutube color="#ff0000" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box width="50%" mt={2}>
                    <FormDataConsumer>
                      {({ formData }) =>
                        formData.youtubeURL ? (
                          parseYoutube.valid(formData.youtubeURL) ? (
                            <Microlink
                              media="image"
                              url={formData.youtubeURL}
                              size="small"
                            />
                          ) : (
                            <IncompleteLink>
                              Noch keine vollständige Youtube URL
                            </IncompleteLink>
                          )
                        ) : (
                          <IncompleteLink />
                        )
                      }
                    </FormDataConsumer>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box width="50%" pr={4}>
                    <TextInput
                      source="spotifyURL"
                      label="Spotify URL"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FaSpotify color="#1DB954" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box width="50%" mt={2}>
                    <FormDataConsumer>
                      {({ formData }) => {
                        if (!formData.spotifyURL) {
                          return <IncompleteLink />;
                        }
                        try {
                          const found = Boolean(
                            parseSpotify.parse(formData.spotifyURL).uri
                          );
                          if (!found) {
                            return (
                              <IncompleteLink>
                                Noch keine vollständige Spotify URL
                              </IncompleteLink>
                            );
                          } else {
                            return (
                              <Microlink
                                url={formData.spotifyURL}
                                media="image"
                                size="small"
                              />
                            );
                          }
                        } catch (e) {
                          return (
                            <IncompleteLink>
                              Noch keine vollständige Spotify URL
                            </IncompleteLink>
                          );
                        }
                      }}
                    </FormDataConsumer>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box mx={3}>
              <Typography variant="h6">Tickets</Typography>
              <Box display="flex" alignItems="center">
                <Box flex="2">
                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      return (
                        <TextInput
                          disabled={formData.free}
                          multiline
                          fullWidth
                          rows={2}
                          source="price"
                          label="Preise"
                          {...rest}
                        />
                      );
                    }}
                  </FormDataConsumer>
                </Box>
                <Box flex="3" ml={3}>
                  <BooleanInput source="free" label="Kostenlos" />
                </Box>
              </Box>
              <TextInput source="ticketURL" label="Ticket URL" fullWidth />
            </Box>
            <Toolbar>
              <FormDataConsumer>
                {({ formData }) => (
                  <Box display="flex" width="100%" alignItems="center">
                    <SaveButton
                      label={
                        formData.statePublished === "PUBLIC"
                          ? "Speichern"
                          : "Speichern & Veröffentlichen"
                      }
                      transform={(data) => ({
                        ...data,
                        statePublished: "PUBLIC",
                      })}
                      saving={formProps.saving}
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect
                      }
                    />
                    {formData.statePublished === "DRAFT" && (
                      <Flex ml={3}>
                        <SaveButton
                          color="inherit"
                          label={
                            formData.statePublished === "PUBLIC"
                              ? "Nicht Mehr Veröffentlichen"
                              : "Speichern"
                          }
                          transform={(data) => ({
                            ...data,
                            statePublished: "DRAFT",
                          })}
                          saving={formProps.saving}
                          handleSubmitWithRedirect={
                            formProps.handleSubmitWithRedirect
                          }
                        />
                      </Flex>
                    )}
                    <Flex dir="row" flex={1} ml={3}>
                      <Text>
                        ist aktuell{" "}
                        {formData.statePublished === "PUBLIC"
                          ? "veröffentlicht."
                          : "ein Entwurf"}
                        &nbsp;
                      </Text>
                      {formData.statePublished === "PUBLIC" && (
                        <Link
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            formProps.save(
                              {
                                ...formData,
                                statePublished: "DRAFT",
                              },
                              ""
                            );
                          }}
                          style={{ color: th.palette.info.dark }}
                        >
                          <Text color="inherit">Zu Entwurf umwandeln.</Text>
                        </Link>
                      )}
                    </Flex>
                    <DeleteConfirmButton {...formProps} redirect="list" />
                  </Box>
                )}
              </FormDataConsumer>
            </Toolbar>
          </form>
        </>
      )}
    />
  );
}

function EventEditTabContent(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { data: occurrencesData, loading } = useGetManyReference(
    "occurrences",
    "event_id",
    props.id,
    { page: 1, perPage: 300 },
    { field: "id", order: "ASC" },
    {},
    "event"
  );
  const fetchedOnce = useRef(false);
  const [occurrences, updateOccurrences] = useImmer({});
  const occurrencesRef = useRef(null);
  const [updateOccurrencesMutation] = useGQLMutation(
    gql`
      mutation ($deleted: [bigint!], $created: [occurrences_insert_input!]!) {
        delete_occurrences(where: { id: { _in: $deleted } }) {
          affected_rows
        }
        insert_occurrences(
          objects: $created
          on_conflict: {
            constraint: occurrences_pkey
            update_columns: [startDate, endDate, cancelled, title, data]
          }
        ) {
          affected_rows
        }
      }
    `,
    {}
  );

  const onSuccess = useCallback(
    ({ data }) => {
      const deleted = Object.values(occurrencesRef.current)
        .filter(({ isDeleted, isNew }) => isDeleted && !isNew)
        .map(({ id }) => id);
      const created = Object.values(occurrencesRef.current)
        .filter(({ isDeleted }) => !isDeleted)
        .map(({ id, isNew, startDate, endDate, data, title, cancelled }) => ({
          id: isNew ? undefined : id,
          startDate,
          endDate,
          title,
          data,
          cancelled,
          event_id: props.id,
        }));
      updateOccurrencesMutation({
        variables: {
          deleted,
          created,
        },
      });
      refresh();
      notify(`Änderungen gespeichert`);
      redirect("/");
    },
    [notify, props.id, redirect, refresh, updateOccurrencesMutation]
  );
  useEffect(() => {
    occurrencesRef.current = occurrences;
  }, [occurrences]);
  useEffect(() => {
    if (
      occurrencesData &&
      fetchedOnce.current !== true &&
      !loading &&
      Object.values(occurrencesData).length > 0
    ) {
      fetchedOnce.current = true;
      updateOccurrences((draft) => {
        Object.values(occurrencesData).forEach(
          ({ id, startDate, endDate, title, cancelled, data }) => {
            draft[String(id)] = {
              id,
              startDate,
              endDate,
              title,
              cancelled,
              data,
              isNew: false,
              isDeleted: false,
            };
          }
        );
      });
    }
  }, [loading, occurrencesData, updateOccurrences]);

  return (
    <Edit
      {...props}
      mutationMode={"optimistic"}
      title={<EventTitle />}
      resource="events"
      onSuccess={onSuccess}
    >
      <EventForm
        occurrences={occurrences}
        updateOccurrences={updateOccurrences}
        {...props}
      />
    </Edit>
  );
}

function EventEdit(props) {
  return (
    <Box>
      <EventEditTabContent {...props} />
      <Required />
    </Box>
  );
}

export { EventEdit, EventForm, EssentialRow };
