import React from "react";
import { TouchableOpacity, TouchableOpacityProps } from "react-native";

type FeedbackStyle = "opacity" | "highlight" | "native";

interface TouchableProps extends TouchableOpacityProps {
  feedbackStyle?: FeedbackStyle;
  children?: any;
}

function Touchable({ feedbackStyle = "opacity", ...rest }: TouchableProps) {
  return (
    <TouchableOpacity
      //@ts-ignore
      dataSet={{ styleRule: `touchable-${feedbackStyle}` }}
      activeOpacity={0.8}
      {...rest}
    />
  );
}

export { Touchable };
