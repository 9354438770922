import React, { useState, useMemo, useCallback } from "react";
import { useField } from "react-final-form";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Cloudinary } from "cloudinary-core";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const createCloudinary = (cloudName, unsignedUploadPreset, onGotHandle) => ({
  load: (source, load, error, progress, abort, headers) => {
    var cl = new Cloudinary({ cloud_name: cloudName });
    var myRequest = new Request(cl.url(source));
    fetch(myRequest).then(function (response) {
      response.blob().then(function (myBlob) {
        load(myBlob);
      });
    });
  },
  process: (fieldName, file, metadata, load, error, progress, abort) => {
    // `fieldName` and `meta` are not used for now

    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
    const xhr = new XMLHttpRequest();
    const formData = new FormData();

    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    xhr.upload.addEventListener("progress", (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    });

    xhr.onreadystatechange = (e) => {
      if (xhr.readyState !== 4) {
        return;
      }

      if (xhr.status >= 200 && xhr.status < 300) {
        const response = JSON.parse(xhr.responseText);
        load(response.public_id);
        onGotHandle(response.public_id);
        return;
      }

      error("oh no!");
    };

    formData.append("upload_preset", unsignedUploadPreset);
    formData.append("tags", "browser_upload");
    formData.append("file", file);
    xhr.send(formData);

    return {
      abort: () => {
        xhr.abort();
      },
    };
  },
  revert: null,
});

const CloudinaryInput = ({ source }) => {
  const {
    input: { onChange, value },
  } = useField(source);
  const [files, setFiles] = useState(
    value ? [{ source: value, options: { type: "local" } as const }] : []
  );
  const cloudinaryServer = useMemo(
    () =>
      createCloudinary("hcabadqds", "qrgbqmal", (u) =>
        onChange({ target: { value: u } })
      ),
    [onChange]
  );
  const updateFiles = useCallback(
    (files) => {
      setFiles(files);
      if (files.length === 0) {
        onChange({ target: { value: "" } });
      }
    },
    [onChange]
  );
  return (
    <FilePond
      files={files}
      onupdatefiles={updateFiles}
      allowMultiple={false}
      server={cloudinaryServer}
      name="files"
      labelIdle={
        'Bild hier reinziehen oder <span class="filepond--label-action">auswählen</span>'
      }
      stylePanelAspectRatio={"4:3"}
      styleProgressIndicatorPosition="center"
      styleLoadIndicatorPosition="center"
    />
  );
};
export { CloudinaryInput };
