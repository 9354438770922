import React from "react";
import {
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
  ReferenceInput,
  SelectInput,
  TextInput,
  ReferenceManyField,
  TextField,
  Show,
  Datagrid,
  EditButton,
  TabbedShowLayout,
  Tab,
  useRedirect,
} from "react-admin";
import { CloudinaryInput } from "components/CloudinaryInput";
import { Typography, Box, InputAdornment } from "@material-ui/core";
import { Required, required } from "components/Required";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaPhoneAlt,
  FaLink,
} from "react-icons/fa";
import { CloudinaryField } from "components/CloudinaryField";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";

function ProfileToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton redirect="/" submitOnEnter={true} />
    </Toolbar>
  );
}

function ProfileFieldsEssential() {
  return (
    <>
      <TextInput
        validate={required}
        required
        source="name"
        label="Profilname"
        fullWidth
      />
      <ReferenceInput
        validate={required}
        required
        source="city_id"
        fullWidth
        label="Region"
        reference="cities"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        validate={required}
        required
        source="tagline"
        label="Tagline (Kurzbeschreibung)"
        fullWidth
      />
      <TextInput
        validate={required}
        required
        source="description"
        label="Beschreibung"
        multiline
        rows={5}
        fullWidth
      />
      <CloudinaryInput source="image_handle" />
    </>
  );
}

function ProfileFieldsContact() {
  return (
    <>
      <Typography variant="h6">Kontakt</Typography>
      <TextInput
        source="phone"
        label="Telefonnummer"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaPhoneAlt color="#888" />
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        source="website"
        label="Webseite (URL)"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaLink color="#888" />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

function ProfileFieldsSocial() {
  return (
    <>
      <Typography variant="h6">Social Media</Typography>
      <TextInput
        source="facebookPage_url"
        label="Facebook Seite (URL)"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaFacebook color="#1877f2" />
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        source="instagram_username"
        label="Instagram @name"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaInstagram color="#c32aa3" />
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        source="twitter_username"
        label="Twitter @name"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaTwitter color="#1da1f2" />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

function EatProps({ children }) {
  return children;
}

function ProfileEdit(props) {
  const redirect = useRedirect();
  return (
    <Box maxWidth="600px">
      <Show {...props} title="Profil" actions={null}>
        <TabbedShowLayout>
          <Tab label="Infos">
            <Edit mutationMode={"optimistic"} {...props} title=" ">
              <SimpleForm redirect="/" toolbar={<ProfileToolbar />}>
                <ProfileFieldsEssential />
                <ProfileFieldsContact />
                <ProfileFieldsSocial />
              </SimpleForm>
            </Edit>
            <Required />
          </Tab>
          <Tab label="Locations">
            <EatProps>
              <Box mt={3}>
                <Button
                  onClick={() => redirect("/locations/create")}
                  startIcon={<Add />}
                >
                  Location Hinzufügen
                </Button>
              </Box>
            </EatProps>
            <ReferenceManyField
              label=" "
              reference="locations"
              target="profile_id"
            >
              <Datagrid>
                <CloudinaryField source="image_handle" label="Bild" />
                <TextField source="name" label="Name" />
                <TextField source="address" label="Adresse" />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </Box>
  );
}

export {
  ProfileEdit,
  ProfileFieldsEssential,
  ProfileFieldsContact,
  ProfileFieldsSocial,
};
