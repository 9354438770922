import React from "react";
import { Text, Flex } from "components/Design";
import { formatForTimezone } from "components/DateTimeField";
import { CloudinaryAvatar } from "components/CloudinaryAvatar";

interface Occurrence {
  startDate: string;
  endDate: string;
  event: any;
}

function OccurrenceField({ record }: { record: Occurrence }) {
  if (record && record.event) {
    const date =
      String(new Date(record.startDate)) !== "Invalid Date"
        ? new Date(record.startDate)
        : undefined;
    return (
      <Flex dir="row" alignItems="center">
        <CloudinaryAvatar
          handle={record.event.coverImage_handle}
          name={record.event.name}
        />
        <Flex ml={2}>
          <Text bold>{record.event.name}</Text>
          <Text>{date ? formatForTimezone(date) : "Kein Datum"}</Text>
        </Flex>
      </Flex>
    );
  } else {
    return (
      <Flex dir="row" alignItems="center">
        <CloudinaryAvatar name={"?"} />
        <Flex ml={2}>
          <Text bold>Fehler bei Vorschau</Text>
          <Text>Keine Infos gefunden</Text>
        </Flex>
      </Flex>
    );
  }
}

export { OccurrenceField };
