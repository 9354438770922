import React, { cloneElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  Filter,
  TextInput,
  BooleanInput,
  Pagination,
  useRedirect,
} from "react-admin";
import { CloudinaryField } from "components/CloudinaryField";
import { AgoField } from "components/DateTimeField";
import { StatsBox } from "components/Boxes";
import { Text, Flex } from "components/Design";
import { utcToZonedTime, format } from "date-fns-tz";
import { Warning, Add } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import AddContent from "img/AddContent.svg";

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} label="Event erstellen" />
    </TopToolbar>
  );
};

const ListFilter = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput
      label="Suche"
      resettable
      source="name@_ilike,description@_ilike"
      alwaysOn
    />
    <BooleanInput
      source="in_past"
      label="Vergangene Events anzeigen"
      alwaysOn
    />
  </Filter>
);

function StatusField({ source, record = {}, label = "" }) {
  const draft = record[source] === "DRAFT";
  return (
    <Flex
      color={draft ? "util.white" : "success.main"}
      borderRadius="4px"
      py={0}
      px={2}
      bg={draft ? "warning.dark" : undefined}
      alignItems="center"
      border="2px solid"
      borderColor={draft ? "warning.dark" : "success.main"}
    >
      <Text color="inherit" bold variant="button">
        {draft ? "Entwurf" : "Live"}
      </Text>
    </Flex>
  );
}

function formatForTimezone(date) {
  return format(utcToZonedTime(date, "Europe/Berlin"), "dd.MM.yyyy HH:mm", {
    timeZone: "Europe/Berlin",
  });
}

function OccurrencesField({ source, record = {} as any, label = "" }) {
  const past = record.in_past;
  const next =
    record.startDate_next && formatForTimezone(record.startDate_next);
  const last =
    record.startDate_last && formatForTimezone(record.startDate_last);
  const count = record.occurrences_count;
  if (count === 0 || (!past && !next)) {
    return (
      <Flex dir="row" alignItems="center">
        <Warning />
        <Flex ml={1}>Termin fehlt</Flex>
      </Flex>
    );
  } else if (past) {
    return <span>{`${last} (vorbei)`}</span>;
  } else if (count > 1) {
    return (
      <span>{`${next} (+${count - 1} weitere${
        count - 1 === 1 ? "r" : ""
      })`}</span>
    );
  } else {
    return <span>{next}</span>;
  }
}

function EmptyEventList() {
  const redirect = useRedirect();
  return (
    <Flex alignItems="center" width="100%" pt={4} pb={6} px={4}>
      <img src={AddContent} style={{ width: "80%", maxWidth: 320 }} alt="" />
      <Flex style={{ opacity: 0.6 }} py={4} alignItems="center">
        <Text bold variant="h4" textAlign="center">
          Hier gibt es noch keine Events
        </Text>
        <Text variant="h5" my={1} textAlign="center">
          Wenn du welche erstellst, werden sie hier erscheinen.
        </Text>
      </Flex>
      <Button
        onClick={() => redirect("/events/create")}
        size="large"
        variant="contained"
        color="primary"
        startIcon={<Add />}
      >
        Event Erstellen
      </Button>
    </Flex>
  );
}

function EventPagination(props) {
  return (
    <Pagination
      rowsPerPageOptions={[50]}
      limit={<EmptyEventList />}
      {...props}
    />
  );
}

function EventList(props) {
  return (
    <div>
      <StatsBox />
      <List
        {...props}
        empty={<EmptyEventList />}
        pagination={<EventPagination />}
        bulkActionButtons={false}
        title="Events"
        filterDefaultValues={{ in_past: false }}
        filters={<ListFilter />}
        perPage={25}
        actions={<ListActions />}
      >
        <Datagrid
          rowClick="edit"
          rowStyle={(record, index) => ({
            backgroundColor:
              record.statePublished === "DRAFT" ? "#fdffe8" : undefined,
          })}
        >
          <CloudinaryField
            size={36}
            label="Bild"
            source="coverImage_handle"
            face={true}
          />
          <TextField source="name" />
          <StatusField source="statePublished" label="Status" />
          <OccurrencesField source="startDate_next" label="Termine" />
          <AgoField source="updated_at" label="Bearbeitet" />
          <AgoField source="created_at" label="Erstellt" />
        </Datagrid>
      </List>
    </div>
  );
}

export { EventList };
