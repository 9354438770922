// in src/MyAppBar.js
import * as React from "react";
import { AppBar as DefaultAppBar, Link, Button, useLogout } from "react-admin";
import { Box, Typography } from "@material-ui/core";
import logo from "img/logo.png";
import { PowerSettingsNew } from "@material-ui/icons";

function AppBar(props) {
  const logout = useLogout();
  return (
    <DefaultAppBar
      {...props}
      userMenu={
        <Button
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
          color="inherit"
          label="Abmelden"
        >
          <PowerSettingsNew />
        </Button>
      }
    >
      {/* @ts-ignore */}
      <Link to="/">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "white",
          }}
        >
          <img
            src={logo}
            width={24}
            height={24}
            style={{ marginRight: 8 }}
            alt="Kulta Logo"
          />
          <Typography variant="h6" color="inherit">
            KULTA
          </Typography>
        </div>
      </Link>
      <Box display="flex" flex="1" justifyContent="center">
        <Typography variant="h6" color="inherit" id="react-admin-title" />
      </Box>
    </DefaultAppBar>
  );
}
export { AppBar };
