import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Create,
  Toolbar,
  SaveButton,
  SimpleForm,
  useCreateController,
  useRedirect,
} from "react-admin";
import {
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {
  ProfileFieldsEssential,
  ProfileFieldsContact,
  ProfileFieldsSocial,
} from "./ProfileEdit";
import { Required } from "components/Required";

function ProfileCreateToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton label="Weiter" redirect="/" submitOnEnter={true} />
    </Toolbar>
  );
}

function ProfileCreate({ resource = "profiles", basePath = "#" }) {
  const [address, setAddress] = useState("");
  const [accessible, setAccessible] = useState(false);
  const addressRef = useRef("");
  const accessibleRef = useRef("");
  const createController = useCreateController({
    resource: "locations",
    basePath: "#",
  });
  const redirect = useRedirect();

  useEffect(() => {
    addressRef.current = address;
  }, [address]);

  useEffect(() => {
    accessibleRef.current = accessible;
  }, [accessible]);

  const onSuccess = useCallback(
    ({ data }) => {
      createController.save(
        {
          name: data.name,
          address: addressRef.current,
          city_id: data.city_id,
          image_handle: data.image_handle,
          wheelchairAccessible: accessibleRef.current,
        },
        null
      );
      redirect("/");
    },
    [createController, redirect]
  );
  return (
    <Box maxWidth="600px">
      <Box m={3}>
        <Typography variant="h5">
          Wilkommen bei KULTA - Schön, dass du dabei bist!
        </Typography>
        <Typography>
          Fülle bitte zuerst dein Veranstalterprofil aus. Du kannst dein Profil
          jederzeit in Deinem Nutzerkonto bearbeiten.
        </Typography>
      </Box>
      <Create
        onSuccess={onSuccess}
        resource={resource}
        basePath={basePath}
        title="Profil"
        undoable={false}
      >
        <SimpleForm redirect="/" toolbar={<ProfileCreateToolbar />}>
          <ProfileFieldsEssential />
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            // @ts-ignore
            fullWidth
          >
            <Typography variant="h6">Deine Location</Typography>
            <Typography>
              Du kannst in deinem Profil später weitere Locations anlegen
            </Typography>
            <Box my={3}>
              <TextField
                variant="filled"
                name={"address"}
                multiline={true}
                rows={2}
                value={address}
                onChange={({ target: { value } }) => setAddress(value)}
                label={"Adresse"}
                size="small"
                fullWidth
              />
              <Box mt={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={accessible}
                      onChange={({ target: { checked } }) =>
                        setAccessible(checked)
                      }
                      name="accessibleCheckbox"
                    />
                  }
                  label="Für RollstuhlfahrerInnen zugänglich"
                />
              </Box>
            </Box>
          </Box>
          <ProfileFieldsContact />
          <ProfileFieldsSocial />
        </SimpleForm>
      </Create>
      <Required />
    </Box>
  );
}

export { ProfileCreate };
