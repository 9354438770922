import germanMessages from "ra-language-german";
import englishMessages from "ra-language-english";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { raRelationshipsLanguageEnglish } from "@react-admin/ra-relationships";

import { resolveBrowserLocale } from "react-admin";

const i18nProvider = polyglotI18nProvider(
  (locale) =>
    locale === "en"
      ? { ...englishMessages, ...raRelationshipsLanguageEnglish }
      : { ...germanMessages, ...raRelationshipsLanguageEnglish },
  resolveBrowserLocale(),
  { allowMissing: true, onMissingKey: (key, _, __) => key }
);

export { i18nProvider };
