// in src/posts.js
import * as React from "react";
import {
  List,
  BooleanField,
  ChipField,
  Datagrid,
  TextField,
} from "react-admin";

const CollageList = (props) => (
  <List {...props} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="internal" />
      <ChipField source="statePublished" />
    </Datagrid>
  </List>
);

export { CollageList };
